<template>
    <div>
      {{Datasource}}
      <e-charts autoresize :options="bar" ref="bar" theme="ovilia-green" auto-resize />
    </div>
</template>

<script>
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import theme from './theme.json'

ECharts.registerTheme('ovilia-green', theme)

export default {
  props: ['Datasource'],
  data () {
    return {
      bar: {
        legend: {},
        tooltip: {},
        dataset: {
          // Provide data.
          source: [['Temps', 'Aujourd\'hui', 'Hier'], ['Anoptic riviera', 570000, 1130000], ['Anoptic yopougon', 0, 0]]
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: 'category' },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [{ type: 'bar' }, { type: 'bar' }]

      }
    }
  },
  methods: {
    randomize () {
      return [
        Math.round(300 + (Math.random() * 700)) / 10,
        Math.round(300 + (Math.random() * 700)) / 10
      ]
    }
  },
  components: {
    ECharts
  }
}
</script>
